import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Home2 from './components/Home2';
import ResumePage from './components/ResumePage';
import './styles/style.scss';

// TODO: add loader and other page routes
function App() {
  return (
    <Router>
      {/* <Preloader load={ load } /> */ }
      {/* <div className="App" id={ load ? "no-scroll" : "scroll" }> */ }
      {/* <Navbar /> */ }
      {/* <ScrollToTop /> */ }
      <Routes>
        <Route path="/" element={ <Home2 /> } />
        <Route path='/resume' element={<ResumePage/>}/>
        {/* <Route path="/project" component={ Projects } /> */ }
        {/* <Route path="/about" component={ About } /> */ }
        {/* <Route path="/resume" component={ Resume } /> */ }
      </Routes>
      {/* <Footer /> */ }
      {/* </div> */ }
    </Router>
  );
}

export default App;
