import './style.css';

const About = () => {
    const links = [
        {
            logo: 'fa-facebook',
            link: 'https://www.facebook.com/vikas.surera',
        },
        {
            logo: 'fa-twitter',
            link: 'https://twitter.com/vikassurera',
        },
        {
            logo: 'fa-linkedin',
            link: 'https://www.linkedin.com/in/vikassurera/',
        },
        {
            logo: 'fa-github',
            link: 'https://github.com/Vikas-Surera',
        }
    ]

    const name = {
        firstName: 'Vikas',
        secondName: ' Surera'
    }

    // add logo here

    const tagLine = 'Rise up till you reach your destination';
    const shorBio = "Hi everyone, I am Vikas Surera from Alwar Rajasthan, India.\n" +
        "                   Fullstack developer and DevOps Engineer persuing B. Tech in Mechanical Engineering from IIT Kharagpur.\n";

    function renderSocialLinkItem( logo: string, link: string, index: number ) {
        return <li key={ `social_links_${ index }` } className="list-inline-item">
            <a href={ link } target="_blank">
                <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className={ `fa ${ logo } fa-stack-1x fa-inverse` }></i>
                </span>
            </a>
        </li>;
    }

    function renderSocialLinks() {

        return <ul className="list-inline list-social-icons mb-0">
            {
                links.map( ( item, index ) => renderSocialLinkItem( item.logo, item.link, index ) )
            }
        </ul>;
    }

    return <section className="about-section p-3 p-lg-5 d-flex d-column" id="about">
        <div className="my-auto">
            {/*<img src={this.backgroundImage} className="img-fluid mb-3" alt=""/>*/ }
            <h1 className="mb-0">{ name.firstName }
                <span className="text-primary">{ name.secondName }</span>
            </h1>
            <div className="subheading mb-5">
                { tagLine }
            </div>
            <p className="mb-5" style={ { maxWidth: "500px" } }>{ shorBio }</p>
            {
                renderSocialLinks()
            }
        </div>
    </section>;
}

export default About;