import About from "./About";
import NavBar from "./NavBar";
import Resume from "./Resume";
import TechSpecs from './TechSpecs';

const Home2 = () => {
    return <div id="page-top">
        <NavBar />
        <div className="container-fluid p-0">
            <About />
            <TechSpecs/>
            {/* <Resume /> */}
            {/* <About />
            <Experience /> */}
            {/* <Portfolio/>
                <Skills/>
                <Awards/>
                <Contact/>
                <ProjectModel/> */}
        </div>
    </div>
}

export default Home2;