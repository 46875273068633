import './style.css';

const NavBar = () => {
    const navs: { link: string, text: string }[] = [
        // {
        //     link: '/',
        //     text: 'Vikas Surera',
        // },
        {
            link: '/#about',
            text: 'About',
        },
        {
            link: '/#skills',
            text: "Skills"
        },
        {
            link: '/resume#resume',
            text: 'Resume',
        },
        {
            link: 'https://bloggerboy.co.in',
            text: 'Blogs',
        }
        // {
        //     link: '#portfolio',
        //     text: 'Portfolio',
        // },
        // {
        //     link: '#skills',
        //     text: 'Skills',
        // },
        // {
        //     link: '#awards',
        //     text: 'Awards',
        // },
        // {
        //     link: '#contact',
        //     text: 'Contact',
        // },
    ]

    const profileImage: string = "img/profile.jpg";

    function renderNavItems( link: string, text: string, index: number ) {
        return <li key={ `nav_item_${ index }` } className="nav-item">
            <a className="nav-link js-scroll-trigger" href={ link }>{ text }</a>
        </li>;
    }

    function renderImage() {
        return <a className="navbar-brand js-scroll-trigger" href="#page-top">
            
            <span className="d-none d-lg-block">
                <img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={ profileImage } alt="" />
            </span>
        </a>;
    }

    function renderNavToggle() {
        return <button className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>;
    }

    return <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
        {
            renderImage()
        }
        {
            renderNavToggle()
        }
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
                {
                    navs.map( ( item, index ) => renderNavItems( item.link, item.text, index ) )
                }
            </ul>
        </div>
    </nav>;
}

export default NavBar;