import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './style.css';
// import Github from "./Github";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

function TechSpecs() {
  return (
    <Container fluid className="tech-section" id="skills">
        <div className="col-12">
                <h2 className="  text-center">Skills</h2>
                <div className="mb-5 heading-border"></div>
            </div>
      
      <Container>
        <h3 className="project-heading">
          Professional Skillset
        </h3>

        <Techstack />

        <h3 className="project-heading">
          Tools I use
        </h3>
        <Toolstack />

        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default TechSpecs;
