import About from "./About";
import NavBar from "./NavBar";
import Resume from "./Resume";
import TechSpecs from './TechSpecs';

const ResumePage = () => {
    return <div id="page-top">
        <NavBar />
        <div className="container-fluid p-0">
            
            <Resume />
        </div>
    </div>
}

export default ResumePage;